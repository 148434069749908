// FONT FACE --Grotesk
@font-face {
  font-family: Grotesk;
  font-weight: 600;
  src: url("../fonts/Roc_Grotesk/Kostic\ -\ Roc\ Grotesk\ ExtraWide\ Medium.otf");
  font-display: swap;
}

@font-face {
  font-family: Grotesk;
  font-weight: 400;
  src: url("../fonts/Roc_Grotesk/Kostic\ -\ Roc\ Grotesk\ ExtraWide.otf");
  font-display: swap;
}

@font-face {
  font-family: Grotesk;
  font-weight: 200;
  src: url("../fonts/Roc_Grotesk/Kostic\ -\ Roc\ Grotesk\ ExtraWide\ Light.otf");
  font-display: swap;
}

// Druk Condensed
@font-face {
  font-family: DrukCondensed;
  font-weight: 600;
  src: url("../fonts/Druk_Condensed/DrukXCond-SuperItalic-Trial.otf");
  font-display: swap;
}

// DM Sans
@font-face {
  font-family: DM_Sans;
  font-weight: 300;
  src: url("../fonts/DM_Sans/static/DMSans-Light.ttf");
  font-display: swap;
}

@font-face {
  font-family: DM_Sans;
  font-weight: 400;
  src: url("../fonts/DM_Sans/static/DMSans-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: DM_Sans;
  font-weight: 500;
  src: url("../fonts/DM_Sans/static/DMSans-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: DM_Sans;
  font-weight: 600;
  src: url("../fonts/DM_Sans/static/DMSans-SemiBold.ttf");
  font-display: swap;
}

@font-face {
  font-family: DM_Sans;
  font-weight: 700;
  src: url("../fonts/DM_Sans/static/DMSans-Bold.ttf");
  font-display: swap;
}


//
html body {
  @apply font-body antialiased;
}

h1,
h2,
h3,
h4 {
  @apply font-display;
  font-family: Grotesk;
  font-weight: 600;
  --font-display: DM_Sans;
}

:root {
  --font-display: Grotesk;
  letter-spacing: 1.9px;
  --font-body: DM_Sans;
}

p,
li,
textarea,
label,
select,
option {
  @apply font-body;
  font-weight: 400;
}