@import "./_theme_colors";
@import "./_theme_font";
@import "./_theme_custom";
@import "./_dates_picker";
//
@import "./header";

@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";

#hubspot-messages-iframe-container {
    z-index: 9999 !important;
}

.translate-right {
    transform: translateY(0);
    opacity: 1;
    transform: scale(100%);
}

/* Animation keyframes for translating from the right */
@keyframes carTranslateIn {
    from {
        transform: translateY(50%);
        opacity: 0;
        transform: scale(70%);
    }

    to {
        transform: translateY(0);
        opacity: 1;
        transform: scale(100%);
    }
}


/* Apply the animation to the element */
.car.translate-right {
    animation: carTranslateIn 500ms ease-in-out;
}

// scrollbar 
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-track {
    @apply bg-custom-200;
}

::-webkit-scrollbar-thumb {
    @apply bg-red-500;
}
